<template>
  <div class="home-wrapper">
    <div class="top">
      <div class="box-wrapper map-box">
        <div class="title">
          <span>实时运营地图</span>
        </div>
        <div id="map" class="map"></div>
      </div>
      <div class="box-wrapper order-statistics">
        <div class="title"><span>统计中心</span></div>
        <div class="main-box">
          <div class="count-box">
            <span class="count-title-one">累计充电时长</span>
            <span class="count-data" v-if="chargeTime>10000">{{(chargeTime/10000).toLocaleString()}}万小时</span>
            <span class="count-data" v-else>{{(chargeTime/10000).toLocaleString()}}小时</span>
          </div>
          <div class="count-box">
            <span class="count-title-one">累计充电次数</span>
            <span class="count-data" v-if="chargeNumber>10000">{{(chargeNumber/10000).toLocaleString()}}万次</span>
            <span class="count-data" v-else>{{(chargeNumber/10000).toLocaleString()}}次</span>
          </div>
          <div class="count-box">
            <span class="count-title-one">累计订单数量</span>
            <span class="count-data" v-if="orderNumber>10000">{{(orderNumber/10000).toLocaleString()}}万个</span>
            <span class="count-data" v-else>{{(orderNumber/10000).toLocaleString()}}个</span>
          </div>
          <div class="count-box">
            <span class="count-title-one">当日充电量</span>
            <span class="count-data" v-if="dayChargeAmount>100000000">{{(dayChargeAmount/100000000).toLocaleString()}}万度</span>
            <span class="count-data" v-else>{{(dayChargeAmount/100000000).toLocaleString()}}度</span>
          </div>
        </div>
        <div class="main-box">
          <div class="count-box">
            <span class="count-title-two">当日充电次数</span>
            <span class="count-data">{{dayChargeNumber}}次</span>
          </div>
          <div class="count-box">
            <span class="count-title-two">当日充电时长</span>
            <span class="count-data">{{dayChargeTime}}小时</span>
          </div>
          <div class="count-box">
            <span class="count-title-two">当日充电费用</span>
            <span class="count-data">{{dayChargePrice}}元</span>
          </div>
          <div class="count-box">
            <span class="count-title-two">用户数量</span>
            <span class="count-data">{{userNumber}}个</span>
          </div>
        </div>
        <div class="title"><span>汇总中心</span></div>
        <div class="summary">
          <div class="charge-state" ref="chargeState"></div>
          <div class="order-state" ref="orderState"></div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="box-wrapper recharge-statistics">
        <div class="title">
          <span>充电统计</span>
        </div>
        <div class="charts-box" ref="rechargeEle"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import dayjs from 'dayjs'
import { mapKey, SafeJsCode, center } from '@/libs/api'
import AMapLoader from '@amap/amap-jsapi-loader'
import { UseOfStateDetails,ChargingCapacityStat,GetSiteChargingMemKwhTotle,GetOrderInfoTotal,getMap} from '@/libs/apiBIgScreen'
window._AMapSecurityConfig = {
  securityJsCode: SafeJsCode, //安全密钥
}

export default {
  name: "home.vue",
  data () {
    return {
      chargeTime: 0,//累计充电时长
      chargeNumber: 0,//累计充电次数
      orderNumber: 0,//累计订单数量
      dayChargeAmount: 0,//当日充电量
      dayChargeNumber: 0,//当日充电次数
      dayChargeTime: 0,//当日充电时长
      dayChargePrice: 0,//当日充电费用
      userNumber: 0,//用户数量
      sitePosition: [] //充电站点
    }
  },
  mounted () {
    this.$nextTick(() => {
      setTimeout(() => {
        this.initMap()
        this.initEcharts() //初始化图表
      }, 500)
    })
  },
  methods: {
    async initMap () {
      let AMap = await AMapLoader.load({
        key: mapKey,  //设置您的key
        version: "2.0",
        plugins: ['AMap.DistrictSearch'],   // 需要使用的的插件列表
        Loca:{                // 是否加载 Loca， 缺省不加载
          "version": '2.0'  // Loca 版本，缺省 1.3.2
        },
      })
      getMap().then((res)=>{
        let data = res.data
        if(data.code === 0){
          data.data.forEach((item)=>{
            this.sitePosition.push({
              "type": "Feature",
              "properties": {
                "name": item.siteName.substr(0,6) + "...",
                "num": item.chargePoints.length
              },
              "geometry":{
                "type":"Point",
                "coordinates":[
                  item.stationLng,
                  item.stationLat
                ]
              }
            })
          })
        }
      })
      let features = this.sitePosition
      let siteinfo = {
          "type":"FeatureCollection",
          features
      }
      const district = new AMap.DistrictSearch({subdistrict:0,extensions:'all',level:'province'});
      district.search('四川省',(status, result)=>{
        const bounds = result.districtList[0].boundaries
        const mask = []
        for (let i=0;i<bounds.length;i++){
          mask.push([bounds[i]])
        }
        let map = new AMap.Map("map", {
          viewMode: "2D",
          mask: mask,
          zoom: center.zoom,
          center: [center.lng,center.lat],
          resizeEnable: true, //是否监控地图容器尺寸变化
        })
        // 添加描边
        for (let i=0;i<bounds.length; i++) {
          const polyline = new AMap.Polyline({
            path: bounds[i], // polyline 路径，支持 lineString 和 MultiLineString
            strokeColor: '#00D3FC', // 线条颜色，使用16进制颜色代码赋值。默认值为#00D3FC
            strokeWeight: 2, // 轮廓线宽度,默认为:2
            strokeOpacity: 0.5 //轮廓线透明度,默认为0.9
          })
          polyline.setMap(map);
        }
        //3D立体效果
        let loca = new Loca.Container({
          map
        });
        let geo = new Loca.GeoJSONSource({
          data: siteinfo
        });
        let geos = new Loca.GeoJSONSource({
          url: 'https://geo.datav.aliyun.com/areas_v3/bound/510000_full.json',
        });
        let pl = new Loca.PolygonLayer({
          zIndex: 120,
          opacity: 0.8,
          shininess: 10,
          hasSide: true,
        });

        pl.setSource(geos);
        pl.setStyle({
          topColor: 'rgba(0,0,0,0)',
          sideTopColor: '#dc3912',
          sideBottomColor: '#ff9900',
          height: 20000,
          altitude: 0,
        });
        loca.add(pl);

        //添加充电桩图层
        let labelsLayer = (window.labelsLayer = new Loca.LabelsLayer({
          zooms: [5, 20]
        }));
        labelsLayer.setSource(geo);
        labelsLayer.setStyle({
          icon: {
            type: 'image',
            image: 'https://a.amap.com/Loca/static/loca-v2/demos/mock_data/charging_pile.png',
            size: [48, 75],
            anchor: 'center',
          },
          text: {
            // 每项配置都可使用回调函数来动态配置
            content: (index, feat) => {
              return feat.properties.name;
            },
            style: {
              fontSize: 12,
              fontWeight: 'normal',
              fillColor: '#5CDE8E',
              strokeColor: '#000',
              strokeWidth: 2,
            },
            direction: 'bottom',
          },
          extData: (index, feat) => {
            return feat.properties;
          }
        });
        loca.add(labelsLayer);
        labelsLayer.on('complete', () => {
          let normalMarker = new AMap.Marker({
            offset: [70, -15],
          });
          let labelMarkers = labelsLayer.getLabelsLayer().getAllOverlays();
          for (let marker of labelMarkers) {
            marker.on('mouseover', (e) => {
              let position = e.data.data && e.data.data.position;
              if (position) {
                normalMarker.setContent(
                    '<div>数量：' + marker.getExtData().num + '个' + '</div>',
                );
                normalMarker.setPosition(position);
                map.add(normalMarker);
              }
            });
            marker.on('mouseout', () => {
              map.remove(normalMarker);
            })
          }
        })
      })
    },
    initEcharts(){
      UseOfStateDetails().then((res)=>{
        let data = res.data
        if(data.code === 0){
          if (this.$refs.chargeState == null) {
            return
          }
          echarts.dispose(this.$refs.chargeState)
          let myChart = echarts.init(this.$refs.chargeState)
          let pieColors = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de']
          let richColor = {}
          pieColors.forEach((item, idx) => {
            richColor[`color${idx}`] = {
              fontSize: 12,
              color: item
            }
          })

          let chartData = [
            { value: data.data.normal, name: '正常' },
            { value: data.data.idle, name: '空闲' },
            { value: data.data.occupied, name: '使用' },
            { value: data.data.offLine, name: '离线' },
            { value: data.data.fault, name: '故障' }
          ]

          let option = {
            color: pieColors,
            series: [
              {
                name: '电枪状态',
                type: 'pie',
                radius: ['50%', '80%'],
                center:['50%','56%'],
                avoidLabelOverlap: true,
                label: {
                    formatter: params => {
                        return `{color${params.dataIndex}|${params.name}(${params.value})}`
                    },
                    rich: richColor
                },
                labelLine: {
                  lineStyle: {
                    width: 3
                  }
                },
                data: chartData
              },
              {
                name: '总电枪',
                type: 'pie',
                radius: ['10%', '20%'],
                itemStyle: { // 防止鼠标悬浮到标签时出现放大的点
                  color: 'transparent'
                },
                label: {
                  position: 'inside',
                  formatter: `{data|{c}}\n{serie|{a}}`,
                  rich: {
                  data: {
                    fontWeight: 'bold',
                    fontSize: 16,
                    color: `#fff`,
                    lineHeight: 16,
                    textBorderColor: `transparent`,
                    textBorderWidth: 0
                  },
                  serie: {
                    fontSize: 16,
                    color: `#fff`,
                    lineHeight: 16,
                    textBorderColor: `transparent`,
                    textBorderWidth: 0
                  }
                  }
                },
                labelLine: {
                  show: false
                },
                data: [ // 计算表格数据value总和
                  data.data.total
                ]
              }
            ]
          }
          myChart.setOption(option)
        }
      })
      ChargingCapacityStat({
        type: 1
      }).then((res)=>{
        let data = res.data
        if(data.code === 0){
          let myChart = echarts.init(this.$refs.rechargeEle)
          // x轴数据
          let xAxisData = []
          data.data.forEach((item)=>{
              xAxisData.push(item.time.substr(-2)+'日')
          })
          // series中数据
          let seriesData = []
          data.data.forEach((item)=>{
            seriesData.push(item.totalElectricQuantity/10000)
          })
          let option = {
            color: ["#6fd2ef"],
            tooltip: {
              trigger: "axis",
              borderColor: "#1b366a", // 边框颜色
              formatter: (params) => {
                return `
                ${params[0].name}  <br/>
                ${params[0].marker}
                ${params[0].seriesName} :
                ${params[0].value == 0 ? "-" : Number(params[0].value).toLocaleString()}度`;
              }
            },
            legend: {
              icon: "rect",
              itemHeight: 2,
              textStyle: {
                align: "center",
                color: "#000",
                fontSize: 16
              }
            },
            grid: {
              left: '0',
              right: '10',
              bottom: "10",
              containLabel: true
            },
            xAxis: [
              {
                type: "category",
                splitLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    color: "#000",
                    rotate: -20,   //值>0向右倾斜，值<0则向左倾斜
                    fontSize: 10,
                    interval: 0
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: "#ccc"
                    }
                },
                data: xAxisData
              }
            ],
            yAxis: [
              {
                type: "value",
                splitLine: {
                    show: false,
                    lineStyle: {
                        type: [6, 8],
                        dashOffset: 1,
                        color: "rgba(255, 255, 255, 0.1)"
                    },
                },
                axisLine: {
                    show: false,
                    lineStyle: {
                        color: "#ccc"
                    },
                },
                axisLabel: {
                    show: false,
                    color: "#fff",
                    fontSize: 16
                },
                axisTick: {
                    show: false
                }
              }
            ],
            series: [
              {
                name: "日电量",
                type: "line",
                data: seriesData,
                symbol: "none",
                smooth: true,
                cursor: "default",
                width: 2,
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: "rgba(2, 154, 254,.4)",
                      },
                      {
                        offset: 1,
                        color: "rgba(2, 154, 254, .1)",
                      }
                    ],
                    false
                  ),
                  shadowColor: "rgba(2, 154, 254, .3)",
                  shadowBlur: 2
                }
              }
            ]
          };
          myChart.setOption(option)
        }
      })

      GetSiteChargingMemKwhTotle().then((res)=>{ //统计电站，桩，电量，钱
        let data = res.data
        if(data.code === 0){
          this.siteNum = data.data.total.siteNum
          this.chargingNum = data.data.total.chargingNum
          this.electricNum = data.data.total.electricNum
          this.moneyNum = data.data.total.moneyNum

          this.chargeTime = data.data.total.minsHour
          this.chargeNumber = data.data.total.orderNumber
          this.orderNumber = data.data.total.orderNumber
          this.dayChargeAmount = data.data.day.electricNum
          this.dayChargeNumber = data.data.day.orderNumber.toLocaleString()
          this.dayChargeTime = data.data.day.minsHour.toLocaleString()
          this.dayChargePrice = data.data.day.moneyNum.toLocaleString()
        }
      })

      GetOrderInfoTotal().then((res)=>{
        let data = res.data
        if(data.code === 0){
          let illegalBillRate = data.data.illegalBillRate
          let faultRate = data.data.faultRate
          let completeRate = data.data.completeRate
          let complaintRate = data.data.complaintRate
          this.userNumber = (data.data.userInfoCount + data.data.operatorCount + data.data.enterpriseCount).toLocaleString()
          if (this.$refs.orderState == null) {
            return
          }
          echarts.dispose(this.$refs.orderState)
          let myChartOrder = echarts.init(this.$refs.orderState)//订单非法率，失败率，临停率，完成率
          let dataList = [
            { value: completeRate, name: '完成率' },
            { value: faultRate, name: '非法率' },
            { value: illegalBillRate, name: '失败率' },
            { value: complaintRate, name: '投诉率' }
          ];
          const colorList = ['#50CCCB','#4D88FE','#FFBF3C','#FF0000']
          let richColor = {}
          colorList.forEach((item, idx) => {
              richColor[`color${idx}`] = {
                  fontSize: 12,
                  color: item
              }
          })
          let optionOrder = {
            tooltip: {
              show: true,
              formatter: function (param) {
                  return `
                  ${param.marker}
                  ${param.name} :
                  ${param.value.toLocaleString()}%`
              }
            },
            series: [
              {
                type: 'pie',
                radius: '70%',
                center: ['55%', '50%'],
                label: {
                  formatter: params => {
                    return `{color${params.dataIndex}|${params.name}(${params.value})}`
                  },
                  rich: richColor
                },
                labelLine: {
                  length: 10,
                  length2: 60,
                  lineStyle: {
                      width: 3
                  }
                },
                itemStyle: {
                  color: function (params) {
                    return colorList[params.dataIndex]
                  }
                },
                data: dataList
              }
            ]
          };
          myChartOrder.setOption(optionOrder)
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
  @import "home";
</style>
